import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import DynamicComponent from "../components/dynamic-components/dynamic-component"
import SectionColoumns from "../components/elements/section-columns"
import { graphql } from "gatsby"
import Article from "../components/dynamic-components/article/article"
import AllTopics from "../components/dynamic-components/all-topics"
import SEO from "../components/seo"
import PopOverSelect from "../components/elements/pop-over-select"
import RecipeFilterMobile from "../components/dynamic-components/recipe-filter-mobile"
import { paginate } from "../util"

const RecipeCategoryPage = ({ data, location }) => {
  const [mealType, setMealtype] = useState([])
  const [product, setProduct] = useState([])
  const [dietary, setDietary] = useState([])
  const [mealTypeList, setMealTypeList] = useState([])
  const [productList, setProductList] = useState([])
  const [dietaryList, setDietaryList] = useState([])
  const [filteredRecipes, setFilteredRecipes] = useState([])
  const [totalRecipes, setTotalRecipes] = useState([])
  const [mealTypeOpen, setMealTypeOpen] = useState(false)
  const [productOpen, setProductOpen] = useState(false)
  const [dietaryOpen, setDietaryOpen] = useState(false)
  const [page, setPage] = useState(1)

  const { components, page_title } = data.sanityArticleCategoryPage
  const { allSanityRecipe } = data

  const mealTypeToggle = () => {
    setDietaryOpen(false)
    setProductOpen(false)
    setMealTypeOpen(!mealTypeOpen)
  }
  const productToggle = () => {
    setDietaryOpen(false)
    setMealTypeOpen(false)
    setProductOpen(!productOpen)
  }
  const dietaryToggle = () => {
    setMealTypeOpen(false)
    setProductOpen(false)
    setDietaryOpen(!dietaryOpen)
  }
  const selectItem = (type, current, setter) => {
    if (current.includes(type)) {
      setter([...current.filter(x => x !== type)])
    } else {
      setPage(1)
      setter([...current, type])
    }
  }
  useEffect(() => {
    let auxMealTypeList = []
    let auxProductList = []
    let auxDietaryList = []
    for (const recipe of allSanityRecipe.nodes) {
      auxMealTypeList = [
        ...auxMealTypeList,
        ...recipe.meta.article_meal_type.map(x => x.meal_type_name),
      ]
      auxProductList = [
        ...auxProductList,
        ...recipe.meta.article_collection_ref.map(x => x.collection_title),
      ]
      auxDietaryList = [
        ...auxDietaryList,
        ...recipe.meta.article_dietary.map(x => x.name),
      ]
    }
    setMealTypeList([...new Set(auxMealTypeList)])
    setProductList([...new Set(auxProductList)])
    setDietaryList([...new Set(auxDietaryList)])
    setTotalRecipes([...allSanityRecipe.nodes])
  }, [])
  useEffect(() => {
    if (!mealType && !product && !dietary) {
      setFilteredRecipes([...totalRecipes])
    } else {
      setFilteredRecipes([
        ...totalRecipes
          .filter(x =>
            mealType.length === 0
              ? x
              : x.meta.article_meal_type
                  .map(mealType => mealType.meal_type_name)
                  .some(x => mealType.includes(x))
          )
          .filter(x =>
            product.length === 0
              ? x
              : x.meta.article_collection_ref
                  .map(c => c.collection_title)
                  .some(x => product.includes(x))
          )
          .filter(x =>
            dietary.length === 0
              ? x
              : x.meta.article_dietary
                  .map(d => d.name)
                  .some(x => dietary.includes(x))
          ),
      ])
    }
  }, [mealType, product, dietary, totalRecipes])
  const handleOutsideClick = e => {
    if (
      e.target.classList.contains("pop-over-select__box") ||
      e.target.classList.contains("pop-over-select__item")
    ) {
      return
    }
    if (productOpen) setProductOpen(false)
    if (mealTypeOpen) setMealTypeOpen(false)
    if (dietaryOpen) setDietaryOpen(false)
  }
  const apply = (mealType, dietary, product) => {
    setPage(1)
    setMealtype([...mealType])
    setProduct([...product])
    setDietary([...dietary])
  }

  return (
    <div onClick={handleOutsideClick}>
      <Layout>
        <SEO title={page_title} url={location.pathname} />
        <DynamicComponent components={components} />
        <SectionColoumns isCentered>
          <div className="column is-half">
            <RecipeFilterMobile
              mealTypeList={mealTypeList}
              dietaryList={dietaryList}
              productList={productList}
              apply={apply}
              initialMealType={mealType}
              initialDietary={dietary}
              initialProduct={product}
              totalRecipes={totalRecipes}
            />
            <div className="recipe-filter--tablet">
              {mealTypeList.length > 0 && (
                <PopOverSelect
                  title={"Meal Type"}
                  listItems={mealTypeList}
                  onChange={selectItem}
                  setOpen={mealTypeToggle}
                  open={mealTypeOpen}
                  setter={setMealtype}
                  current={mealType}
                />
              )}
              {dietaryList.length > 0 && (
                <PopOverSelect
                  title={"Dietary"}
                  listItems={dietaryList}
                  setOpen={dietaryToggle}
                  open={dietaryOpen}
                  onChange={selectItem}
                  setter={setDietary}
                  current={dietary}
                />
              )}
              {productList.length > 0 && (
                <PopOverSelect
                  title={"Product"}
                  listItems={productList}
                  setOpen={productToggle}
                  open={productOpen}
                  onChange={selectItem}
                  setter={setProduct}
                  current={product}
                />
              )}
            </div>
          </div>
        </SectionColoumns>
        <SectionColoumns isCentered isMultiline>
          <>
            {paginate(
              filteredRecipes.map(article => {
                return (
                  <div
                    key={article._id}
                    style={{ marginBottom: "3rem" }}
                    className="column is-4"
                  >
                    <Article {...article} />
                  </div>
                )
              }),
              page
            )}
          </>
          {filteredRecipes.length > paginate(filteredRecipes, page).length && (
            <div className="column is-12 has-text-centered">
              <span
                onClick={() => setPage(page + 1)}
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  cursor: "pointer",
                  borderBottom: "2px solid black",
                  paddingBottom: "3px",
                }}
              >
                Load more
              </span>
            </div>
          )}
        </SectionColoumns>
        <AllTopics />
      </Layout>
    </div>
  )
}

export default RecipeCategoryPage

export const query = graphql`
  query {
    allSanityRecipe {
      nodes {
        _id
        slug {
          current
        }
        _type
        title: recipe_title
        meta: recipe_meta_data {
          article_type_ref {
            article_type_title
          }
          article_collection_ref {
            _id
            collection_title
          }
          article_meal_type {
            meal_type_name
            _id
          }
          article_dietary {
            name
          }
        }
        image: recipe_image_image {
          video
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }

    sanityArticleCategoryPage(
      article_type_ref: { slug: { current: { eq: "recipe" } } }
    ) {
      page_title
      components {
        ... on SanityHeroSimple {
          _type
          _key
          cta_title
          title
          collection_reference {
            featured_image {
              asset {
                fluid {
                  src
                }
              }
            }
            slug {
              current
            }
            color {
              section_background
            }
          }
        }
        ... on SanityHeroBanner {
          _key
          _type
          hero_title
          hero_cta {
            cta_text
            cta_link {
              ... on SanityArticle {
                _type
                slug {
                  current
                }
              }
              ... on SanityCollection {
                slug {
                  current
                }
                _type
              }
              ... on SanityProduct {
                slug {
                  current
                }
                _type
              }
              ... on SanityRecipe {
                slug {
                  current
                }
                _type
              }
            }
          }
          hero_primary_section {
            pitch
            ref {
              ... on SanityArticle {
                article_image {
                  asset {
                    fluid(maxWidth: 1000) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
                article_title
                slug {
                  current
                }
                _type
              }
              ... on SanityProduct {
                product_collection {
                  slug {
                    current
                  }
                }
                _type
                slug {
                  current
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    url
                  }
                }
              }
              ... on SanityCollection {
                id
                _type
                slug {
                  current
                }
                featured_image {
                  asset {
                    fluid {
                      src
                    }
                  }
                }
                color {
                  section_background
                }
              }
            }
          }
          hero_secondary_section {
            pitch
            ref {
              ... on SanityArticle {
                slug {
                  current
                }
                _type
                article_meta_data {
                  article_type_ref {
                    article_type_title
                  }
                }
                article_title
                article_image {
                  asset {
                    fluid(maxWidth: 500) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityProduct {
                _type
                slug {
                  current
                }
                product_collection {
                  slug {
                    current
                  }
                }
                product_preview_color {
                  section_background
                }
                product_name
                product_image {
                  asset {
                    fluid(maxWidth: 500) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityCollection {
                id
                slug {
                  current
                }
                featured_image {
                  asset {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        ... on SanityProductTeaser {
          _type
          _key
          cta_section {
            section_cta_cta {
              cta_link {
                ... on SanityArticle {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityCollection {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityDynamicPage {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityProduct {
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityRecipe {
                  _type
                  slug {
                    current
                  }
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
          refs {
            _id
            product_name
            slug {
              current
            }
            _type
            product_variants {
              variant_type
              variants {
                _key
                variant_info_price
                variant_info_value
              }
            }
            product_collection {
              slug {
                current
              }
            }
            product_label {
              label_title
              label_color {
                section_background
              }
            }
            product_image {
              asset {
                fluid(maxWidth: 500) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            product_disabled
          }
        }
        ... on SanitySectionIntroText {
          _key
          _type
          section_link_text
          section_text
          section_title
          section_color {
            section_background
          }
          section_link {
            ... on SanityArticle {
              id
              slug {
                current
              }
              _type
            }
            ... on SanityProduct {
              id
              _type
              slug {
                current
              }
            }
          }
        }
        ... on SanityArticleTeaser {
          _key
          _type
          article_teaser_references {
            ... on SanityArticle {
              _id
              slug {
                current
              }
              title: article_title
              meta: article_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              _type
              image: article_image {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            ... on SanityRecipe {
              _id
              slug {
                current
              }
              _type
              title: recipe_title
              meta: recipe_meta_data {
                article_type_ref {
                  article_type_title
                }
              }
              image: recipe_image_image {
                video
                image {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
          cta_section {
            section_cta_cta {
              cta_link {
                ... on SanityArticle {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityArticleType {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityCollection {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityDynamicPage {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityProduct {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityRecipe {
                  slug {
                    current
                  }
                  _type
                }
              }
              cta_text
            }
            section_cta_paragraph
            section_cta_title
          }
        }
        ... on SanityPageTitle {
          _key
          _type
          page_title_header
          page_title_intro
          topics {
            topics {
              article_type_title
              slug {
                current
              }
            }
          }
        }
        ... on SanityAllTopics {
          _key
          _type
        }
        ... on SanityCollectionTeaser {
          _key
          _type
          collection_references {
            color {
              section_background
            }
            featured_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            slug {
              current
            }
            pitch
            collection_title
            _id
          }
        }
      }
    }
  }
`
