import React, { useState } from "react"
import { CgClose } from "react-icons/cg"
import Drawer from "../elements/drawer"
import { PopOverSelectMobile } from "../elements/pop-over-select"

const RecipeFilterMobile = ({
  mealTypeList,
  dietaryList,
  productList,
  apply,
  initialMealType,
  initialProduct,
  initialDietary,
}) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const [mealType, setMealType] = useState(initialMealType || [])
  const [dietary, setDietary] = useState(initialDietary || [])
  const [product, setProduct] = useState(initialProduct || [])

  const selectItem = (type, current, setter) => {
    if (current.includes(type)) {
      setter([...current.filter(x => x !== type)])
    } else {
      setter([...current, type])
    }
  }

  const clear = () => {
    setMealType([])
    setDietary([])
    setProduct([])
    apply([], [], [])
  }

  return (
    <div className="recipe-filter--mobile">
      <div className="is-hidden-tablet has-text-centered">
        <button
          className="topics-mobile-btn"
          onClick={() => setFilterOpen(true)}
        >
          Filters
        </button>
      </div>
      <Drawer visible={filterOpen}>
        <nav
          className="navbar is-hidden-tablet"
          style={{ marginTop: 60, paddingBottom: "2rem" }}
        >
          <div className="navbar__close-icon-wrapper">
            <div
              style={{ margin: "10px 10px", fontSize: "1.5rem" }}
              onClick={() => setFilterOpen(false)}
            >
              <CgClose />
            </div>
          </div>
          <div
            className="recipe-filter__wrapper--mobile"
            style={{ margin: "0 20px" }}
          >
            {mealTypeList.length > 0 && (
              <PopOverSelectMobile
                title="Meal Type"
                list={mealTypeList}
                onChange={selectItem}
                className="recipe-filter__wrapper--mobile"
                setter={setMealType}
                current={mealType}
              />
            )}
            {dietaryList.length > 0 && (
              <PopOverSelectMobile
                title="Dietary"
                list={dietaryList}
                onChange={selectItem}
                current={dietary}
                setter={setDietary}
                className="recipe-filter__wrapper--mobile"
              />
            )}
            {productList.length > 0 && (
              <PopOverSelectMobile
                title="Product"
                list={productList}
                onChange={selectItem}
                current={product}
                setter={setProduct}
                className="recipe-filter__wrapper--mobile"
              />
            )}
          </div>
        </nav>
        <div
          className="product-page__mobile-add"
          style={{ position: "sticky" }}
        >
          <div className="recipe-filter__mobile-button" onClick={clear}>
            Clear all
          </div>
          <div
            className="recipe-filter__mobile-button"
            onClick={() => apply(mealType, dietary, product)}
          >
            Apply ({mealType.length + dietary.length + product.length})
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default RecipeFilterMobile
