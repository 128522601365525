import React, { memo, useState } from "react"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"

const PopOverSelect = ({
  listItems,
  onChange,
  title,
  open,
  setOpen,
  current,
  setter,
}) => {
  return (
    <div className="pop-over-select">
      <div
        style={open ? { fontWeight: "bold" } : {}}
        className="pop-over-select__button"
        onClick={() => setOpen(!open)}
      >
        {current.length > 0 && <div className="pop-over-select__dot" />}
        {title}
        <div className="pop-over-select__arrow">
          {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
      </div>
      {open && (
        <div className="pop-over-select__box-wrapper">
          <ul className="pop-over-select__box">
            {listItems.map(x => (
              <li
                className={`pop-over-select__item ${
                  current.includes(x) ? "pop-over-select__item--selected" : ""
                }`}
                onClick={() => onChange(x, current, setter)}
                key={x}
              >
                {x}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default PopOverSelect

export const PopOverSelectMobile = ({
  title,
  list,
  onChange,
  type,
  className,
  setter,
  current,
}) => {
  return (
    <div className={className ? className : ""}>
      <div className="pop-over-select__box pop-over-select__box--mobile">
        <p className="navbar__item navbar__item--title pop-over-select__title">
          {title}
        </p>
        {list.map(x => (
          <div
            className={`pop-over-select__item pop-over-select__item--mobile ${current.includes(
              x
            ) && "pop-over-select__item--selected"}`}
            onClick={() => onChange(x, current, setter)}
            key={x}
          >
            {x}
          </div>
        ))}
      </div>
    </div>
  )
}
